import React, { Fragment } from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import ErrorBlock from "../../../shared/Error/ErrorBlock";
import { combineClasses, Debouncer, get, resolveErrorBlockContentByError } from "../../../../utils/utils";
import is from "../../../../utils/is";
import { service_fetchServiceReservationAvailability } from "../../../../services/Service_Service";
import CubeSpinner from "../../../shared/LoadingIndicator/CubeSpinner";
import '../../../../styles/components/ReservationCalendar.scss';
import { E_ReservationState } from "../../../../models/Models_Session";
import Fa from "../../../tools/Icons/Fa";
import { Process_dateToISO, Process_msToDuration } from "../../../../utils/processors";
import SummaryTable from "../../../shared/SummaryTable";
import { E_Currency } from "../../../../models/constants/Constants_Shared";
import { service_getSessionCost } from "../../../../services/Service_Sessions";
import ButtonsConstructor from "../../../shared/ButtonsConstructor";
import { Translate } from "react-localize-redux";
import { NavLink } from "react-router-dom";
import routes from "../../../../routes/routes";

class ReservationCalendar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
			initialized: false,
			loading: false,
			data: [],
			error: null,
			start: null,
			end: null,

			selection: [],

			cost: null,
			currency: E_Currency.CZK,

			screenVerticalOrientation: props.screenVerticalOrientation,
		};

		this._costDebouncer = new Debouncer(300);
	}

	componentDidMount() {
		this._fetchCalendarBlocks();
	}

	componentWillUnmount() {
		this._signal && this._signal.abort();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {onSelectionChange} = this.props;

		if(
			!is.equal(prevState.selection.length, this.state.selection.length) ||
			!is.equal(prevProps.voucherCode, this.props.voucherCode)
		) {
			onSelectionChange(this.state.selection);
			this._costDebouncer.call(() => this._fetchReservationCost());
		}
	}

	_renderCalendarEntry(timeBlock) {
		const {onRedirect} = this.props;

		const timeFormat = new Intl.DateTimeFormat(window.translator.getActiveLanguage(true), {
			hour: "numeric",
			minute: "numeric"
		});

		if(timeBlock.session) {
			return (
				<NavLink
					className={combineClasses(
						"entry",
						E_ReservationState.AVAILABLE
					)}
					to={`${routes.SESSIONS}/${timeBlock.session.id}`}
					onClick={() => onRedirect()}
				>
					<label>{timeFormat.format(new Date(timeBlock.from))}</label>
				</NavLink>
			);
		}
		return <button className={combineClasses("entry", E_ReservationState.UNAVAILABLE)} style={{background: "rgba(255,255,255,0.1)"}} disabled={true}>
			<label>
				{timeFormat.format(new Date(timeBlock.from))}
			</label>
		</button>;
	}

	_renderCalendarButton(timeBlock) {
		const {selection, loading} = this.state;
		const selectionFrom = get(selection, "0.from");
		const selectionTo = get(selection, "$.to");
		const isSelected = !!selection.find(item => item.from == timeBlock.from && item.to == timeBlock.to);
		const timeFormat = new Intl.DateTimeFormat(window.translator.getActiveLanguage(true), {
			hour: "numeric",
			minute: "numeric"
		});
		const now = new Date();
		const isDisabled = loading || timeBlock.availability != E_ReservationState.AVAILABLE || (
			is.empty(selection) ? false : (
				timeBlock.to <= selectionFrom - 1 || timeBlock.from >= selectionTo + 1
			)
		);
		const canInteract = !(timeBlock.from > selectionFrom && timeBlock.to < selectionTo);

		return (
			<button
				className={combineClasses(
					"entry",
					timeBlock.availability,
					isSelected && "selected",
					timeBlock.from > selectionFrom && timeBlock.to < selectionTo && "inner",
					(now.getTime() > timeBlock.from && now.getTime() < timeBlock.to) && "now",
				)}
				onClick={() => canInteract && this._selectBlock(timeBlock)}
				disabled={isDisabled}
			>
				<label>
					<span>{timeFormat.format(new Date(timeBlock.from))}</span>
					{
						(!isDisabled && canInteract) &&
						<Fa icon={isSelected ? "minus" : "plus"} />
					}
				</label>
			</button>
		)
	}

	_renderSummaryTable() {
		const {selection, cost, currency} = this.state;
		const footerDateFormat = new Intl.DateTimeFormat(window.translator.getActiveLanguage(true), {
			year: "numeric",
			month: "numeric",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
		});
		const hasSelection = !is.empty(selection);
		const summaryData = {
			start: hasSelection ? footerDateFormat.format(new Date(selection[0].from)) : null,
			end: hasSelection ? footerDateFormat.format(new Date(selection.lastItem().to)) : null,
			duration: hasSelection ? Process_msToDuration(new Date(selection.lastItem().to).getTime() - new Date(selection[0].from).getTime()) : null,
			total: hasSelection ? cost : null,
			currency,
		}

		return (
			<SummaryTable
				sections={[
					{
						text: "summary",
						theme: "white",
						entries: [
							{
								text: "start",
								value: summaryData.start || '-',
							},
							{
								text: "end",
								value: summaryData.end || '-',
							},
							{
								text: "duration",
								value: summaryData.duration || '-',
							},
							hasSelection &&
							(
								summaryData.total &&
								{
									text: "total",
									value: summaryData.total,
									currency: summaryData.currency,
								}
							)
							||
							{
								text: "total",
								value: "-",
							}
						]
					}
				]}
			/>
		)
	}

	_renderCalendar() {
		const {edit} = this.props;
		const {data, start, end, selection} = this.state;

		const activeLang = window.translator.getActiveLanguage(true);
		const weekDayFormat = new Intl.DateTimeFormat(activeLang, {
			weekday: "long"
		});
		const headerDateFormat = new Intl.DateTimeFormat(activeLang);

		return (
			<Fragment>
				<header>
					<nav>
						<button onClick={() => this._fetchCalendarBlocks(start.offset({day: -7}))}><Fa icon={"chevron-left"}/></button>
						<label>{headerDateFormat.format(start)} - {headerDateFormat.format(end)}</label>
						<button onClick={() => this._fetchCalendarBlocks(start.offset({day: 7}))}><Fa icon={"chevron-right"}/></button>
					</nav>
				</header>

				<nav className={"action-buttons"}>
					<ButtonsConstructor
						buttons={[
							{
								className: "set-today",
								icon: "calendar-day",
								tippy: <Translate id={"today"} />,
								action: () => this._fetchCalendarBlocks(),
								style: {marginRight: "auto"}
							},
							{
								className: "change-orientation",
								icon: "cs-screen-rotation",
								action: () => this.setState(state => ({screenVerticalOrientation: !state.screenVerticalOrientation})),
							},
							{
								className: "clear-selection",
								text: <label><Translate id={"clearSelection"}/></label>,
								action: () => this.setState({selection: []}),
								disabled: is.empty(selection),
							}
						]}
					/>
				</nav>

				<div className={"reservation-calendar"}>
					{
						data.map((day, i) => (
							<Fragment key={i}>
								<div className={"day"}>
									<label className={combineClasses(
										is.today(new Date(day[0].from)) && "today",
									)}>{weekDayFormat.format(new Date(day[0].from))}</label>

									<div className={"hours"}>
										{
											day.map((timeBlock, j) => (
												<Fragment key={j}>
													{
														edit &&
														this._renderCalendarButton(timeBlock, i)
														||
														this._renderCalendarEntry(timeBlock, i)
													}
												</Fragment>
											))
										}
									</div>
								</div>
							</Fragment>
						))
					}
				</div>


				<footer>
					{edit && this._renderSummaryTable()}
				</footer>
			</Fragment>
		)
	}

	render() {
		const {className, style} = this.props;
		const {error, loading, initialized, screenVerticalOrientation} = this.state;

		return (
			<section className={combineClasses(
				"reservation-calendar-wrapper",
				`orientation-${screenVerticalOrientation ? "vertical" : "horizontal"}`,
				className
			)} style={style}>
				{
					is.valid(error) &&
					<ErrorBlock {...resolveErrorBlockContentByError(error)} />
					||
					(loading ? initialized && this._renderCalendar() : this._renderCalendar())

				}
				{
					loading &&
					<CubeSpinner style={!initialized ? {"--cube-color": "var(--secondary-theme-color)"} : {}} block={!initialized}/>
				}
			</section>
		);
	}

	refreshPortalTarget() {
		this.setState({});
	}

	_fetchCalendarBlocks(startDate = new Date()) {
		const {serviceID} = this.props;

		if(serviceID) {
			let start = startDate.setStartOfDay().offset({day: -startDate.getDay() + 1});
			let end = start.offset({day: 6});

			this._signal && this._signal.abort();
			this._signal = new AbortController();
			let signal = this._signal.signal;
			this.setState({loading: true, start, end});
			service_fetchServiceReservationAvailability(
				serviceID,
				Process_dateToISO(start, true),
				Process_dateToISO(end, true, true),
				signal,
			).then(data => {
				this.setState({data: this._processData(data, start, end)});
			}, error => {
				this.setState({error});
			}).finally(() => {
				this.setState({loading: false, initialized: true});
			});
		}
	}

	_processData(data) {
		let days = [[]];
		let activeDayI = 0;
		let activeDay = new Date(data[0].from);

		data.forEach(item => {
			let from = new Date(item.from);
			if(!(from.getFullYear() == activeDay.getFullYear() && from.getMonth() == activeDay.getMonth() && from.getDate() == activeDay.getDate())) {
				days.push([]);
				activeDay = new Date(item.from);
				activeDayI++;
			}
			days[activeDayI].push(item);
		});

		return days;
	}

	_selectBlock(timeBlock) {
		this.setState(state => {
			let {selection} = state;

			if(is.empty(selection)) {
				return {selection: [timeBlock]};
			}

			const isSelected = !!selection.find(item => item.from == timeBlock.from && item.to == timeBlock.to);
			if(isSelected) {
				return {
					selection: [...selection].filter(item => !(item.from == timeBlock.from && item.to == timeBlock.to))
				}
			}

			return {selection: [...selection, timeBlock].sort((a, b) => a.from - b.from)}
		})
	}

	_fetchReservationCost() {
		const {serviceID, voucherCode} = this.props;
		const {selection} = this.state;

		if(!is.empty(selection)) {
			this.setState({sending: true});
			service_getSessionCost({
				serviceID,
				voucherCode,
				reservationFrom: selection[0].from,
				reservationTo: selection.lastItem().to,
			}).then(session => {
				this.setState({cost: session.cost.total, currency: session.tariff.currency})
			}, () => {
				this.setState({cost: NaN});
			}
			).finally(() => this.setState({loading: false}));
		}
	}

	static get sharedTypes() {
		return {
			screenVerticalOrientation: PropTypes.bool,
		}
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...this.sharedTypes,
			serviceID: PropTypes.number,
			voucherCode: PropTypes.string,
			onSelectionChange: PropTypes.func,
			edit: PropTypes.bool,
			onRedirect: PropTypes.func,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			...this.sharedTypes,

			//If already initialized, change display style of spinner
			initialized: PropTypes.bool,
			loading: PropTypes.bool,
			data: PropTypes.array,
			error: PropTypes.object,
			//Start date
			start: PropTypes.object,
			//End date
			end: PropTypes.object,
			cost: PropTypes.number,
			currency: PropTypes.string,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			serviceID: null,
			onSelectionChange: (selection) => null,
			edit: true,
		}
	}
}

export default ReservationCalendar;
